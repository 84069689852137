$sm-width: 600px;
$md-width: 960px;
$lg-width: 1280px;
$xl-width: 1920px;

@mixin mobile {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$md-width}) and (max-width: #{$lg-width}) {
    @content;
  }
}