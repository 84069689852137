@import '@/scss/media.scss';

.menuBar {
  box-shadow:0px 1px 7px 0px rgba(0, 0, 0, 0.05) !important;
  padding: 0 20px;
  @include mobile {
    padding: 0;
  }
}

.menuBtn {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.searchIcon {
  color: #A2A2A2;
}

.searchWrap {
  margin-left: 20px;
  position: relative;
  width: 280px;
  background-color: #F5F5F5;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  color: #333333;
  display: flex;
  align-items: center;
  @include mobile {
    display: none;
  }
  i {
    color: #A6ABB2;
  }
}

.serachInput {
  flex: 1;
  input {
    font-size: 14px;
    flex: 1;
    padding: 4px 8px;
    width: 100%;
    font-weight: bold;
    color: #A6ABB2;
  }
}

.mobileOnlyItem {
  display: none !important;
  @include mobile {
    display: inline-flex !important;
  }
}

.mobileInvisibleItem {
  display: inline-flex !important;
  @include mobile {
    display: none !important;
  }
}

.invalidate {
  text-align: center;
}