@import './iconfont.scss';
@import '@/scss/media';

$mainColor: #0EC38B;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #F5F5F5;
  overflow-x: hidden;
  font-family: Arial,
  Helvetica,
  sans-serif;
  font-weight: 400;
}

#root {
  min-height: 100%;
}

h1, h2, h3, h4, h5 {
  color: #333;
}

h2 {
  margin: 10px 0px 15px 0px;
}

.main-container {
  max-width: 1600px;
  margin:auto;
  width: 100%;
  padding: 20px 40px;
  @include mobile {
    padding: 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .main-container {
    padding: 0 10px;
  }
}

.flex-one {
  flex: 1;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.inherit-link {
  color: inherit !important;
  text-decoration: none !important;
}

.player-icon-rew,
.player-icon-speed,
.player-icon-pause {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.player-icon-pause {
  font-size: 50px;
  margin: 0 18px;
}

.lyric-highlight {
  color: $mainColor !important;
}

.hidden {
  display: none;
}

body {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
}

.primary-button {
  background-color: $mainColor !important;
  color: #fff !important;
}

.progress-wrap {
  > div {
    margin: 20px;
  }
  svg {
    color: $mainColor !important;
  }
}

.lm-img-desc {
  text-align: center;
  line-height: 16px;
  font-size: 13px;
  margin: 10px auto;
  color: #696969;
  max-width: 600px;
}

.MuiInput-underline:after {
  border-bottom-color: $mainColor !important;
}

b[w] {
  font-weight: inherit;
}

b[w]:active {
  background-color: #E5E6E4;
}

.text-highlighted {
  background-color: #E5E6E4;
}

.pagination-wrapper {
  text-align: center;
  margin-top: 30px;
  .pagination {
    display: inline-block;
    .Mui-selected {
      background-color: #02c386 !important;
      color: white;
    }
  }
}

.ads {
  background-color: #eee;
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  color: #aaa;
  text-align: center;
}

.invalidate {
  text-align: center;
}